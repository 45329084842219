 <template>
 
      <v-dialog width=400px v-model="showVerifyPin">
 
          <v-card  class="mx-auto" max-width="400">
            
                      <v-card-title class="primary white--text">PIN Verification</v-card-title>

                      <v-card-title style="height: 70px;" dense>
                               <v-text-field class="pt-1 pl-1"
                                  dense
                                  type="number"
                                  v-model = "pin"
                                  outlined
                                  autofocus
                                  prefix="PIN #:"
                                ></v-text-field>
                      </v-card-title>
                   

                      <v-card-actions >
                        <v-spacer></v-spacer>
                        <v-btn
                          @click = "cancelVerification"
                          color="orange"
                          text
                        >
                          Cancel
                        </v-btn>

                        <v-btn
                          @click = "processVerification"
                          color="orange"
                          text
                        >
                          Continue
                        </v-btn>
                      </v-card-actions>

         
         
          </v-card>

      </v-dialog>
 
  </template>

<script>

  import {store} from '../main.js'

  export default {
    name       : 'AdministratorView',
    methods    : {
        processVerification() {
            this.showVerifyPin = false;
        },
        cancelVerification() {
            this.showVerifyPin = false;
            this.$router.back();
        }

    },
    data() {
      return {
          showVerifyPin    : true,
          pin              :  "",
       };
    },
  }    
</script>

<style scoped>
</style>